<template>
  <router-view/>
</template>

<style>
</style>

<script>
export default {
  data() {
    return {}
  },
}
</script>
