// External modules
import {createApp} from 'vue';
import store from './modules/store/index';
import cookies from 'vue-cookies';

// App-file
import App from './App.vue';

// Mixins
import functionsMixin from './modules/mixin/functions';
import configMixin from './modules/mixin/config';
import loginMixin from './modules/mixin/login';

// Router
import createRouter from './modules/router/index';

// CSS
import './assets/css/style.css';
import './assets/css/fira_sans/css.css';


// Create app and configure router, mixins, plugins, etc.
const app = createApp(App).use(store);

const router = createRouter(app);
app.use(router);

app.use(cookies)
    .mixin(configMixin)
    .mixin(functionsMixin)
    .mixin(loginMixin)
    .mount('#app');