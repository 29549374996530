import {createStore} from 'vuex'

export default createStore({
    state() {
        return {
            pageTitle: "Dashboard",
            reevaluateLogin: 0,
            confirmationOverlay: {},
            breadCrumbs: [],
            user: {},
            toast: {},
        }
    },
    getters: {},
    mutations: {
        setPageTitle(state, title) {
            state.pageTitle = title;
            document.title = title + " | Screenmanagement Stadt Sonneberg";
        },
        setUser(state, user) {
            state.user = user;
        },
        setToast(state, status) {
            status.visible = true;
            state.toast = status;
        },
    },
    actions: {},
    modules: {}
})
