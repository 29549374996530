import {createRouter as createVueRouter, createWebHistory} from 'vue-router';

function createRoutes(ignored_app) {
    const routes = [
        {
            path: '/',
            components: {
                default:
                    () => import(/* webpackChunkName: "dashboard" */ '../../components/views/dashboard.vue'),
            }
        },
    ];
    const pages = []; // ToDo: Add pages!
    for(const page of pages) {
        try {
            const tmp = require("./" + page + ".js");
            routes.push(tmp);
        } catch(err) {
            console.warn(err.message);
        }
    }

    const route404 = {
        path: '/:catchAll(.*)',
        components: {
            default: () => import(/* webpackChunkName: "notFound" */ '../../components/views/notFound.vue'),
        }
    };
    // noinspection JSCheckFunctionSignatures
    routes.push(route404);

    return routes;
}

export default (app) => {
    return createVueRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes: createRoutes(app),
    })
}
