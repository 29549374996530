import configFile from '../../../config.js';

export default {
  data() {
    const config = {};
    for(const key in configFile) {
      config[key] = configFile[key];
    }
    return config;
  }
}